<template>
  <div>
    <b-card
      title="รายการฝากเงิน"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="แอดมิน"
                label-for="mc-first-name"
              >
                <v-select
                  v-model="tableQuery.staff_id"
                  label="title"
                  :options="adminOptions"
                  :reduce="admin => admin.value"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                label="รายละเอียด"
                label-for="mc-last-name"
              >
                <b-form-input
                  id="mc-last-name"
                  v-model="tableQuery.fullname"
                  placeholder="ชื่อ-สกุล"
                />
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="6">
              <b-form-group
                label="การกระทำ"
                label-for="mc-city"
              >
                <b-row>
                  <b-col md="6">
                    <v-select
                      v-model="tableQuery.action"
                      label="title"
                      :options="bankListOptions"
                    >
                      <template #option="{ text, value }">
                        <img
                          :src="getBankImage(value)"
                          style="width: 24px;"
                        >
                        <span> {{ text }}</span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      id="mc-city2"
                      v-model="tableQuery.bank_acc_no"
                      placeholder="รายละเอียด"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group
                label="วันที่รายการ"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchActivities"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row>
      <b-col md="12">
        <b-card
          title="รายการการกระทำ"
        >
          <b-table
            ref="activitiesLogTable"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="tableQuery.page_size"
            :current-page="tableQuery.page"
            :items="fetchActivitiesLog"
            :total-rows="totalRows"
            :fields="activityLogsFields"
          >
            <template #cell(staff)="data">
              <div v-if="data.item.staff">
                {{ data.item.staff.phoneno }}<br>
                {{ data.item.staff.name }}
              </div>
            </template>
            <template #cell(fullname)="data">
              <div v-if="data.item.user">
                {{ `${data.item.user.fname} ${data.item.user.lname}` }}
              </div>
            </template>
            <template #cell(detail)="data">
              {{ `${data.item.detail}` }}
              <span v-if="data.item.accno"><br>{{ `${data.item.bank} ${data.item.accno}` }}</span>
              <span
                v-if="data.item.note"
                style="color:red;"
              ><br>({{ data.item.note }})</span>
            </template>
            <template #cell(bank_acc_no)="data">
              <img
                :src="getBankImage(data.item.user.bank_code)"
                style="width: 24px;"
              > {{ `${data.item.user.bank_name}` }}<br>{{ `${data.item.user.bank_acc_no}` }}
            </template>
            <template #cell(amount)="data">
              <div style="text-align: right;">
                {{ `${numberWithCommas(data.item.amount)}` }}
              </div>
            </template>
            <template #cell(note)="data">
              <div
                v-if="data.item.slip_url"
                style="text-align: right;"
              >
                <span
                  title="show"
                  @click="viewSlip(data.item)"
                >หลักฐาน</span>
              </div>
              {{ data.item.note }}
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="tableQuery.page_size"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="tableQuery.page"
                :total-rows="totalRows"
                :per-page="tableQuery.page_size"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BInputGroupAppend, BInputGroupPrepend, BInputGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import store from '@/store'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
    BInputGroupAppend,
    BInputGroupPrepend,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      adminOptions: [],
      isBusy: false,
      refRecheck: null,
      modalSlip: { show: false },
      modalMarkComplete: {
        show: false,
        reason: '',
        meta: {},
      },
      modalMatchUser: {
        show: false,
        reason: '',
        meta: {},
        user: {
          phoneno: '',
          fname: '',
          lname: '',
          bank: '',
          bank_code: '',
          bank_acc_no: '',
        },
      },
      pageOptions: [25, 50, 100, 200, 500, 1000],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 25,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      activityLogsFields: [
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: val => this.formatDate(val),
        },
        { key: 'staff', label: 'แอดมิน' },
        { key: 'action', label: 'การกระทำ' },
        { key: 'description', label: 'รายละเอียด' },
        { key: 'btn_actions', label: 'จัดการ' },
      ],
      completedTableFields: [
        { key: 'slip_datetime', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'user', label: 'ลูกค้า' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'bank_acc_no', label: 'บัญชีลูกค้า' },
        { key: 'detail', label: 'รายการ' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'tx_acc', label: 'บัญชีฝาก' },
        { key: 'note', label: 'หมายเหตุ' },
      ],
      /* eslint-disable global-require */
      successItems: [
      ],
      unsuccessItems: [
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    const ctx = this
    ctx.fetchAdmin()
    this.totalRows = this.successItems.length
  },
  methods: {
    getHtmlImageSlip(url) {
      return `<img src="${url}">`
    },
    searchActivities() {
      this.$refs.activitiesLogTable.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      }
      if (this.$refs.activitiesLogTable)
        this.$refs.activitiesLogTable.refresh()
    },
    async fetchAdmin(ctx) {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: { staffs } } = await this.$http.get(`admin${query !== '' ? (`?${query}`) : ''}`)
      staffs.data.forEach(staff => {
        this.adminOptions.push({ title: `${staff.phoneno} ${staff.name}`, value: staff.id })
      })
    },
    async fetchActivitiesLog() {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: { logs: _logs } } = await this.$http.get(`report/activity-logs${query !== '' ? (`?${query}`) : ''}`)
      this.successItems = _logs.data
      // this.unsuccessItems = _deposits.deposits.data.filter(i => (i.processed === 'N'))
      this.totalRows = _logs.meta.total
      return this.successItems
    },
    viewSlip(data) {
      this.modalSlip.show = true
      this.modalSlip.slip_url = data.slip_url
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tooltip { top: 0; }
</style>
